import type { TSportEvent } from 'markets-store'
import { EVENT_ALLOWED_STATES } from 'markets-store/constants'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение списка всех спортивных событий
 * @example
 * const sportEvents = useSportEvents()
 */
export function useSportEvents(): ComputedRef<TSportEvent[]> {
  const MS = useMarketsStore()

  return computed(() =>
    MS.sportEvents.filter((event) =>
      EVENT_ALLOWED_STATES.includes(event.state),
    ),
  )
}
